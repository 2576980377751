
@use '~@/assets/sass/design.sass' as *;

.gallery {
  &Inner {
    display: flex;
    margin-bottom: $base-spacing;
  }

  &Item {
    position: relative;
  }

  &Item~&Item {
    margin-left: $base-spacing;
  }
}

.library {
  max-height: 85vh;
  overflow: auto;

  &Button {
    padding: $base-spacing;
  }
}

.placeholderIcons~.placeholderIcons {
  margin-left: $base-spacing;
}
